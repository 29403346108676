<template>
  <div class="system-view-container">
    <div class="admin-view-container" v-loading="loading">
      <div class="admin-table-search" style="font-size: 20px; font-weight: 800">费用规则</div>
      <div class="admin-table-list">
        <div class="admin-table-list-content" v-if="showTemplateFlag">
          <h3>补考费报销会员服务</h3>
          <el-table :data="type1Data" border>
            <el-table-column label="名称" prop="label"></el-table-column>
            <el-table-column label="设置" prop="value">
              <template slot-scope="scope">
                <span v-if="scope.row.label == '服务次数上限' || scope.row.label == ''">
                  科目{{ scope.$index == 3 ? '一' : scope.$index == 4 ? '二' : '三' }} 服务上限
                  <span style="color: #ff422d">{{ scope.row.value }}</span>
                  （服务次数达上限后，赔付金额没有达到上限，其他科目仍可以继续申请）
                </span>
                <span v-else-if="scope.$index == 0">
                  <span v-for="(item, index) in scope.row.subIncome" :key="index">
                    <span style="margin-right: 10px; word-break: break-word" v-if="item.income">
                      {{ item.sub }}:<span style="color: #ff422d">{{ item.income + '元' }}</span>
                    </span>
                  </span>
                </span>
                <span v-else>
                  {{ scope.row.value }}
                </span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Decimal from 'decimal.js';
import {schSchoolGetExpenseTemplate} from '../../api/index';
export default {
  data() {
    return {
      loading: false,
      templateCode: {
        // sub1: [
        //   {
        //     vehicleType: 'A1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'A2',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'A3',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'B1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'B2',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'C1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'C2',
        //     income: '',
        //     compensation: '',
        //   },
        // ],
        // sub2: [
        //   {
        //     vehicleType: 'A1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'A2',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'A3',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'B1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'B2',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'C1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'C2',
        //     income: '',
        //     compensation: '',
        //   },
        // ],
        // sub3: [
        //   {
        //     vehicleType: 'A1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'A2',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'A3',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'B1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'B2',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'C1',
        //     income: '',
        //     compensation: '',
        //   },
        //   {
        //     vehicleType: 'C2',
        //     income: '',
        //     compensation: '',
        //   },
        // ],
        // other: [
        //   {label: '科目一次数上限', value: '', key: 'sub1Limit'},
        //   {label: '科目二次数上限', value: '', key: 'sub2Limit'},
        //   {label: '科目三次数上限', value: '', key: 'sub3Limit'},
        //   {label: '补偿总额限制(元)', value: '', key: 'totalLimit'},
        //   {label: '保单有效期', value: '', key: 'expiration'},
        // ],
      },
      type1Data: [
        {label: '收费金额', value: ''},
        {label: '补偿金额', value: ''},
        {label: '服务有效期', value: ''},
        {label: '服务次数上限', value: ''},
        {label: '', value: ''},
        {label: '', value: ''},
      ],
      str: {
        sub1Limit: '科目一次数上限',
        sub2Limit: '科目二次数上限',
        sub3Limit: '科目三次数上限',
        totalLimit: '补偿总额限制(元)',
        expiration: '保单有效期',
      },
      applyData: [
        // {label: '收费金额', value: ''},
        // {label: '补偿金额', value: ''},
        // {label: '保单有效期', value: ''},
        // {label: '是否退费', value: ''},
      ],
      applySyr: {
        income: '收费金额',
        compensation: '补偿金额',
        expiration: '保单有效期',
        returnType: '是否退费',
      },
      showTemplateFlag: true,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      schSchoolGetExpenseTemplate().then((res) => {
        this.loading = false;
        if (res.data.code == 0) {
          var type1 = [];
          var type2 = [];
          if ((res.data.data.length == 1 && res.data.data[0].templateType == 2) || res.data.data.length == 0) {
            this.showTemplateFlag = false;
          }
          res.data.data.forEach((item) => {
            if (item.templateType == 1) {
              type1.push(item);
              const subObj = {
                A1: 0,
                A2: 0,
                A3: 0,
                B1: 0,
                B2: 0,
                C1: 0,
                C2: 0,
              };
              const item1 = JSON.parse(item.templateCode);
              const obj = JSON.parse(item.templateCode).other;
              item1.sub1.forEach((item) => {
                subObj[item.vehicleType] = Decimal(subObj[item.vehicleType]).add(item.income).toFixed(0);
              });
              item1.sub2.forEach((item) => {
                subObj[item.vehicleType] = Decimal(subObj[item.vehicleType]).add(item.income).toFixed(0);
              });
              item1.sub3.forEach((item) => {
                subObj[item.vehicleType] = Decimal(subObj[item.vehicleType]).add(item.income).toFixed(0);
              });

              this.type1Data[0].subIncome = [];
              for (const key in subObj) {
                this.type1Data[0].subIncome.push({sub: key, income: subObj[key]});
              }
              this.type1Data[1].value = obj.totalLimit + '元';
              this.type1Data[2].value = obj.expiration + '个月';
              this.type1Data[3].value = obj.sub1Limit + '次';
              this.type1Data[4].value = obj.sub2Limit + '次';
              this.type1Data[5].value = obj.sub3Limit + '次';
            }
            if (item.templateType == 2) {
              type2.push(item);
            }
          });
          if (type1.length) {
            if (type1.length == 1) {
              let template = JSON.parse(type1[0].templateCode);
              template.other = Object.keys(template.other).map((key) => {
                return {key: key, label: this.str[key], value: template.other[key]};
              });
              this.templateCode = template;
            } else {
              type1.forEach((item) => {
                if (item.templateWay == 1) {
                  let template = JSON.parse(item.templateCode);
                  template.other = Object.keys(template.other).map((key) => {
                    return {key: key, label: this.str[key], value: template.other[key]};
                  });
                  this.templateCode = template;
                }
              });
            }
          }

          if (type2.length) {
            if (type2.length == 1) {
              let template = JSON.parse(type2[0].templateCode);
              template = Object.keys(template).map((key) => {
                return {key: key, label: this.applySyr[key], value: template[key]};
              });
              this.applyData = template;
              this.applyData[2].value += '个月';
              this.applyData[3].value = this.applyData[3].value == 1 ? '否' : '是';
            } else {
              type2.forEach((item) => {
                if (item.templateWay == 1) {
                  let template = JSON.parse(type2[0].templateCode);
                  template = Object.keys(template).map((key) => {
                    return {key: key, label: this.applySyr[key], value: template[key]};
                  });
                  this.applyData = template;
                  this.applyData[2].value += '个月';
                  this.applyData[3].value = this.applyData[3].value == 1 ? '否' : '是';
                }
              });
            }
          }
        } else {
          this.$message.error(res.data.msg);
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.system-view-container {
  .admin-view-container {
    height: auto;
    .admin-table-list {
      .admin-table-list-content {
        width: 45%;
      }
    }
    .el-form {
      margin: 0 auto;
    }
  }
}
</style>
